import { template as template_fdfe9ba227cf4483b63f2e5c75b33c7d } from "@ember/template-compiler";
const FKLabel = template_fdfe9ba227cf4483b63f2e5c75b33c7d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
