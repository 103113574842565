import { template as template_69e4b54801924c7f8fcec25bd470b2ab } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_69e4b54801924c7f8fcec25bd470b2ab(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
